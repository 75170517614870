import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Breadcrumb } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import RecipeCard from "../components/recipeCard"

import { categoryInfo } from "../components/categoryInfo"

const CategoryTemplate = ({ location, data: { category }, pageContext }) => {
  const searchQuery = location.state?.searchQuery

  const { uri } = pageContext
  var {sideBarOptions, dropDownOptions, mainCategoryName, mainCategoryLink} = categoryInfo(uri)

  var main_category_breadcrumb = null
  if (category.name === 'Appetizers'
     || category.name === 'Baking'
     || category.name === 'Beverages'
     || category.name === 'Cuisines'
     || category.name === 'Cooking Methods'
     || category.name === 'Chili/Soups/Stews'
     || category.name === 'Desserts/Pies'
     || category.name === 'Fruit'
     || category.name === 'Kid Friendly'
     || category.name === 'Meat/Fish/Seafood'
     || category.name === 'Occasions'
     || category.name === 'Outdoor Cooking'
     || category.name === 'Sandwiches'
     || category.name === 'Special'
     || category.name === 'Salads'
     || category.name === 'Side Dish'
     || category.name === 'Vegetables'
     || category.name === 'This & That'
     ) 
  {
    main_category_breadcrumb = <></>
  } else if (category.name === 'Breakfast'
     || category.name === 'Pasta'
     || category.name === 'Casseroles'
     || category.name === 'Quick Meals'
     ) {
    main_category_breadcrumb = <></>
    //sideBarOptions = undefined
    dropDownOptions = undefined
  } else {
    main_category_breadcrumb = <Breadcrumb.Item href={mainCategoryLink}>{mainCategoryName}</Breadcrumb.Item>
  }

  return (
    <Layout pageInfo={{ pageName: category.name || " Recipes", searchQuery: searchQuery }} sideBarOptions={sideBarOptions} dropDownOptions={dropDownOptions}>
      <Seo title={category.name + " Recipes"} keywords={[`recipes`, category.name]} description={category.name+" listing"} url={uri} />
      <Container>
        <Row className="justify-content-left my-3">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/recipe-index">Recipes</Breadcrumb.Item>
          {main_category_breadcrumb}
          <Breadcrumb.Item active>{category.name}</Breadcrumb.Item>
        </Breadcrumb>
        </Row>
        { category.name === 'Dutch Oven' ? 
          <Row>
              <h5>See our <Link to="/dutch-oven-cooking-tips">Dutch Oven Cooking Tips</Link></h5>
          </Row>
          :
          <></>
        }
        { category.name === 'Camping' ? 
          <Row>
              <h5><Link to="/camping-cookbook">Also see our Camping Cookbook</Link></h5>
          </Row>
          :
          <></>
        }
      </Container>
      <Container className="text-center">
        <Row className="justify-content-center my-3">
          <h1>"{category.name}" Recipes</h1>
        </Row>
        
        <Row className="justify-content-center d-flex flex-wrap">
            {category.posts.nodes.map(post => (
              <RecipeCard
                key={post.uri}
                title={post.title}
                uri={post.uri}
                image={post.featuredImage === null ? null : post.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData}
                excerpt={post.excerpt}
                className="recipeSearchCard"
              />
            ))}
        </Row>
      </Container>
    </Layout>
    )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    category: wpCategory(id: { eq: $id }) {
      id
      uri
      name
      posts {
        nodes {
          uri
          title
          excerpt
          slug
          id
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 300)
                }
              }
            }
          }
        }
      }
    }
  }
`